/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useState } from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useDebounce, useIsGuest } from '../../hooks';
import { AutoComplete, CrimeReport, Rents } from '../../components';
import { PreciselyAutocompleteItem } from '../../types';
import { PropertyAttributes } from './components';
import {
	useGuestPropertyAutocomplete,
	useGuestPropertyDetails,
	usePropertyAutocomplete,
	usePropertyDetails,
} from './queries';

const PropertyLookup: React.FC = () => {
	const [addressQuery, setAddressQuery] = useState('');
	const [address, setAddress] = useState<PreciselyAutocompleteItem>({
		coordinates: [0, 0],
		text: '',
	});

	const isGuest = useIsGuest();

	const debouncedSearchQuery = useDebounce(addressQuery, 500);

	const {
		data: userPropertyAutocompletData,
		isLoading: userPropertyAutocompletLoading,
	} = usePropertyAutocomplete(debouncedSearchQuery, !isGuest);

	const {
		data: guestPropertyAutocompletData,
		isLoading: guestPropertyAutocompletLoading,
	} = useGuestPropertyAutocomplete(debouncedSearchQuery, isGuest);

	const propertyAutocompleteData = isGuest
		? guestPropertyAutocompletData
		: userPropertyAutocompletData;

	const propertyAutocompleteLoading = isGuest
		? guestPropertyAutocompletLoading
		: userPropertyAutocompletLoading;

	const {
		data: userPropertyDetailsData,
		isLoading: userPropertyDetailsLoading,
	} = usePropertyDetails(address, !isGuest);

	const {
		data: guestPropertyDetailsData,
		isLoading: guestPropertyDetailsLoading,
	} = useGuestPropertyDetails(address, isGuest);

	const propertyDetailsData = isGuest
		? guestPropertyDetailsData
		: userPropertyDetailsData;

	const propertyDetailsLoading = isGuest
		? guestPropertyDetailsLoading
		: userPropertyDetailsLoading;

	return (
		<Box p="4">
			<Grid
				templateColumns={{ base: '1fr', lg: '65% 1fr' }}
				templateRows={{ base: 'repeat(1fr, 4)', lg: 'auto auto 1fr' }}
				templateAreas={{
					base: `"search"
					"report"
					"rents"
					"info"`,
					lg: `"search info"
				"report info"
				"rents info"`,
				}}
				columnGap="15px"
				rowGap="20px">
				<GridItem gridArea="search">
					<Box>
						<AutoComplete
							label="Search by property address"
							value={addressQuery}
							placeholder="Search..."
							setValue={setAddressQuery}
							isLoading={propertyAutocompleteLoading}
							onSelectItem={item => {
								const property =
									propertyAutocompleteData?.value?.models[
										item.id
									];
								if (item && property) {
									setAddress(property);
								} else {
									setAddress({
										coordinates: [0, 0],
										text: '',
									});
								}
							}}
							items={propertyAutocompleteData?.value?.models.map(
								(elem, index) => ({
									id: index,
									name: elem.text,
								}),
							)}
						/>
					</Box>
				</GridItem>
				<GridItem gridArea="report">
					<CrimeReport
						loading={propertyDetailsLoading}
						items={
							propertyDetailsData?.value?.crimes?.length
								? propertyDetailsData?.value?.crimes[0].crimeIndexTheme.indexVariable.map(
										elem => ({
											name: elem.name,
											value: elem.category,
										}),
								  )
								: []
						}
					/>
				</GridItem>
				<GridItem gridArea="info">
					<PropertyAttributes
						loading={propertyDetailsLoading}
						data={propertyDetailsData?.value?.attributes}
					/>
				</GridItem>
				<GridItem gridArea="rents">
					<Rents
						loading={propertyDetailsLoading}
						studio={propertyDetailsData?.value?.fmr0 || 0}
						br1={propertyDetailsData?.value?.fmr1 || 0}
						br2={propertyDetailsData?.value?.fmr2 || 0}
						br3={propertyDetailsData?.value?.fmr3 || 0}
						br4={propertyDetailsData?.value?.fmr4 || 0}
						countyName={
							propertyDetailsData?.value?.countyName || ''
						}
						stateCode={propertyDetailsData?.value?.stateCode || ''}
					/>
				</GridItem>
			</Grid>
		</Box>
	);
};

export default PropertyLookup;
