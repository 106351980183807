import { useQuery } from 'react-query';
import { ApiResponse, PreciselyAutocompleteItem } from '../../../types';
import API from '../../../api/api.base';

export const usePropertyAutocomplete = (
	debouncedSearchQuery: string,
	isEnabled: boolean,
) => {
	return useQuery<
		ApiResponse<{
			models: PreciselyAutocompleteItem[];
		} | null>
	>(
		['property-research', debouncedSearchQuery],
		() =>
			API.get('/api/app/precisely/property/autocomplete', {
				Address: debouncedSearchQuery,
			}),
		{
			enabled: isEnabled && !!debouncedSearchQuery,
			refetchOnMount: false,
		},
	);
};
