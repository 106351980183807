/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Suspense, useEffect } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';
// Attempt to fix "error loading dynamically imported module"
import reactLazyWithRetry from '@fatso83/retry-dynamic-import/react-lazy';
import {
	DashboardLayout,
	Loader,
	PandaDocContainer,
	ProtectedRoleArea,
	VideoInstructionModal,
} from './components';

import {
	useGetCurrentUserErrorHandling,
	useMessengerNotificationsListener,
	usePersistData,
	useRegisterNotifications,
} from './hooks';
import { Claim, Role } from './types';
import {
	useDefaultUserStore,
	usePersistDealExplorerStore,
	useVideoInstructionStore,
} from './store';
import { ServerMessages } from './pages/Messenger/Server/ServerMessages';
import { DirectMessages } from './pages/Messenger/Direct/DirectMessages';
import { DirectPage } from './pages/Messenger/Direct/DirectPage';
import { ServerPage } from './pages/Messenger/Server/ServerPage';
import { LinkItems, USER_PERSISTED_KEY } from './constants';
import {
	MainForm,
	NameForm,
	PasswordForm,
	PhoneForm,
} from './pages/MyProfile/components';
import { MyProfileRoutes } from './pages/MyProfile/helper';
import { CruzLayout } from './modules/cruz-ctrl/components';
import PropertyLookup from './pages/PropertyLookup/PropertyLookup';

const Courses = reactLazyWithRetry(() => import('./pages/Courses/Courses'));
const Course = reactLazyWithRetry(() => import('./pages/Course/Course'));
const CourseSection = reactLazyWithRetry(
	() => import('./pages/CourseSection/CourseSection'),
);
const CityResearch = reactLazyWithRetry(
	() => import('./pages/CityResearch/CityResearch'),
);
const DealCalculator = reactLazyWithRetry(
	() => import('./pages/DealCalculator/DealCalculator'),
);
const DealExplorer = reactLazyWithRetry(
	() => import('./pages/DealExplorer/DealExplorer'),
);
const FMRExplorer = reactLazyWithRetry(
	() => import('./pages/FMRExplorer/FMRExplorer'),
);
const MessengerLayout = reactLazyWithRetry(
	() => import('./pages/Messenger/MessengerLayout'),
);
const Forbidden = reactLazyWithRetry(() => import('./pages/403/Forbidden'));
const BillingPage = reactLazyWithRetry(
	() => import('./pages/BillingHistory/BillingHistory'),
);
const CommunityInstruction = reactLazyWithRetry(
	() => import('./pages/CommunityInstruction/CommunityInstruction'),
);
const TenantFinder = reactLazyWithRetry(
	() => import('./pages/TenantFinder/TenantFinder'),
);

// CRUZ-CTRL Routes

const Dashboard = reactLazyWithRetry(
	() => import('./modules/cruz-ctrl/pages/Dashboard/Dashboard'),
);
const Properties = reactLazyWithRetry(
	() => import('./modules/cruz-ctrl/pages/Properties/Properties'),
);
const Tenants = reactLazyWithRetry(
	() => import('./modules/cruz-ctrl/pages/Tenants/Tenants'),
);
const Invoices = reactLazyWithRetry(
	() => import('./modules/cruz-ctrl/pages/Invoices/Invoices'),
);
const Planner = reactLazyWithRetry(
	() => import('./modules/cruz-ctrl/pages/Planner/Planner'),
);

const Property = reactLazyWithRetry(
	() => import('./modules/cruz-ctrl/pages/Property/Property'),
);

const BusinessEntity = reactLazyWithRetry(
	() => import('./modules/cruz-ctrl/pages/BusinessEntity/BusinessEntity'),
);
const DataSource = reactLazyWithRetry(
	() => import('./modules/cruz-ctrl/pages/DataSource/DataSource'),
);

const Users = reactLazyWithRetry(
	() => import('./modules/cruz-ctrl/pages/Users/Users'),
);

const MailAccounts = reactLazyWithRetry(
	() => import('./modules/cruz-ctrl/pages/MailAccounts/MailAccounts'),
);

const Tenant = reactLazyWithRetry(
	() => import('./modules/cruz-ctrl/pages/Tenant/Tenant'),
);

const TermsOfService = reactLazyWithRetry(
	() => import('./pages/TermsOfService/Tos'),
);

const PrivacyPolicy = reactLazyWithRetry(
	() => import('./pages/PrivacyPolicy/PrivacyPolicy'),
);

const MessagingLayout = reactLazyWithRetry(
	() =>
		import(
			'./modules/cruz-ctrl/pages/Messaging/MessagingLayout/MessagingLayout'
		),
);

const MessagingPage = reactLazyWithRetry(
	() =>
		import(
			'./modules/cruz-ctrl/pages/Messaging/MessagingPage/MessagingPage'
		),
);

const SuspenseLoader = (
	<div>
		<Loader centerProps={{ minH: '100vh' }} />
	</div>
);

export const AppRoutes: React.FC = () => {
	const { data } = useGetCurrentUserErrorHandling();

	if (data && !data?.success) {
		throw new Error('App Routes');
	}

	const { permissionId, isAgreementSigned, claimsIds, roleId } =
		data?.value || {};

	const isGuest = roleId === Role.Guest;

	const { isOpen, onClose } = useVideoInstructionStore();
	const { getPersistedValue, newUserReset } =
		usePersistData(USER_PERSISTED_KEY);
	const { resetFiltersState } = usePersistDealExplorerStore();
	useMessengerNotificationsListener();
	useRegisterNotifications();

	const { setUser } = useDefaultUserStore();
	useEffect(() => {
		if (data) {
			setUser(data.value);

			const storedUserData = getPersistedValue('user', 'id');

			if (storedUserData && storedUserData === data.value.userId) {
				return;
			}
			newUserReset(data.value.userId);
			resetFiltersState();
		}
	}, [data]);

	if (!permissionId && isAgreementSigned === undefined) {
		return null;
	}

	return (
		<>
			<PandaDocContainer
				isAgreementSigned={!!isAgreementSigned}
				isGuest={isGuest}>
				<Routes>
					<Route path="/" element={<DashboardLayout />}>
						<Route
							path="/"
							element={
								claimsIds?.length ? (
									<Navigate
										to={
											LinkItems.find(
												elem =>
													elem.id ===
													Array.from(
														claimsIds,
													).sort()[0],
											)!.link
										}
										replace={true}
									/>
								) : (
									<div>root</div>
								)
							}
						/>
						<Route path="/forbidden" element={<Forbidden />} />
						<Route
							path="fmr-explorer"
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(
											Claim['FMR Explorer'],
										)
									}>
									<FMRExplorer />
								</ProtectedRoleArea>
							}
						/>
						<Route
							path="courses"
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(Claim.Courses)
									}>
									<Courses />
								</ProtectedRoleArea>
							}
						/>
						<Route
							path="city-research"
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(
											Claim['City Research'],
										)
									}>
									<CityResearch />
								</ProtectedRoleArea>
							}
						/>
						<Route
							path="courses/:id"
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(Claim.Courses)
									}>
									<Course />
								</ProtectedRoleArea>
							}
						/>
						<Route
							path="courses/:courseId/:sectionId"
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(Claim.Courses)
									}>
									<CourseSection />
								</ProtectedRoleArea>
							}
						/>
						<Route
							path="property-lookup"
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(
											Claim['Property Lookup'],
										)
									}>
									<PropertyLookup />
								</ProtectedRoleArea>
							}
						/>
						<Route
							path={MyProfileRoutes.MY_PROFILE}
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(
											Claim['My Profile'],
										)
									}>
									<MainForm />
								</ProtectedRoleArea>
							}
						/>
						<Route
							path={
								MyProfileRoutes.MY_PROFILE +
								'/' +
								MyProfileRoutes.NAME
							}
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(
											Claim['My Profile'],
										)
									}>
									<NameForm />
								</ProtectedRoleArea>
							}
						/>
						<Route
							path={
								MyProfileRoutes.MY_PROFILE +
								'/' +
								MyProfileRoutes.PHONE
							}
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(
											Claim['My Profile'],
										)
									}>
									<PhoneForm />
								</ProtectedRoleArea>
							}
						/>
						<Route
							path={
								MyProfileRoutes.MY_PROFILE +
								'/' +
								MyProfileRoutes.PASSWORD
							}
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(
											Claim['My Profile'],
										)
									}>
									<PasswordForm />
								</ProtectedRoleArea>
							}
						/>
						<Route
							path={'billing-history'}
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(
											Claim['Billing History'],
										)
									}>
									<BillingPage />
								</ProtectedRoleArea>
							}
						/>
						<Route
							path="deal-calculator"
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(
											Claim['Deal Calculator'],
										)
									}>
									<DealCalculator />
								</ProtectedRoleArea>
							}
						/>
						<Route
							path="deal-explorer"
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(
											Claim['Deal Explorer'],
										)
									}>
									<DealExplorer />
								</ProtectedRoleArea>
							}
						/>

						<Route
							path="messenger"
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(Claim.Messenger)
									}>
									<MessengerLayout />
								</ProtectedRoleArea>
							}>
							<Route
								path="direct"
								element={
									<Suspense fallback={SuspenseLoader}>
										<DirectPage />
									</Suspense>
								}>
								<Route
									path=":id"
									element={<DirectMessages />}
								/>
							</Route>
							<Route
								path="community"
								element={
									<Suspense fallback={SuspenseLoader}>
										<ServerPage />
									</Suspense>
								}>
								<Route
									path=":id"
									element={<ServerMessages />}
								/>
							</Route>
						</Route>

						<Route
							path="tenant-finder"
							element={
								<ProtectedRoleArea
									isAllowed={
										!!claimsIds?.includes(
											Claim.TenantFinder,
										)
									}>
									<TenantFinder />
								</ProtectedRoleArea>
							}
						/>

						<Route
							path="instruction"
							element={
								<Suspense>
									<CommunityInstruction />
								</Suspense>
							}
						/>
					</Route>
					{/*CRUZ_CTRL_ROUTES*/}
					<Route path={'/cruz-ctrl'} element={<CruzLayout />}>
						<Route path={'dashboard'} element={<Dashboard />} />
						<Route path={'properties'} element={<Properties />} />
						<Route path={'tenants'} element={<Tenants />} />
						<Route path={'invoices'} element={<Invoices />} />
						<Route path={'planner'} element={<Planner />} />
						<Route path={'property/:id'} element={<Property />} />
						<Route
							path={'business-entity'}
							element={<BusinessEntity />}
						/>
						<Route path={'data-source'} element={<DataSource />} />
						<Route path={'users'} element={<Users />} />
						{/*<Route*/}
						{/*	path={'mail-accounts'}*/}
						{/*	element={<MailAccounts />}*/}
						{/*/>*/}
						<Route
							path={'tenants/:tenantId'}
							element={<Tenant />}
						/>
						{/*<Route element={<MessagingLayout />} path={'messaging'}>*/}
						{/*	<Route element={<MessagingPage />} path={':id'} />*/}
						{/*</Route>*/}
					</Route>
				</Routes>
			</PandaDocContainer>
			<VideoInstructionModal onClose={onClose} isOpen={isOpen} />
		</>
	);
};
