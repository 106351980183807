/* eslint-disable indent */
import React, { useEffect, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { useChannel } from 'ably/react';
import { Category } from './Category';
import {
	useServerUsersOnlineList,
	useServerUsersAllList,
} from '../../../../../store';
import { Loader } from '../../../../../components';
import { EmptyListMessage } from './EmptyListMessage';
import {
	usePollingServerUsers,
	useHandleRolesSocketMessages,
} from '../../hooks';
import {
	useGetChannelAllUsers,
	useGetChannelOnlineUsers,
} from '../../../queries';
import { filterAblyErrorsToSentry } from '../../../../../utils';

interface IRolesProps {
	channelId?: string;
	isPrivate?: boolean;
	isAdmin: boolean;
	debouncedSearch: string;
}

export const Roles: React.FC<IRolesProps> = ({
	channelId,
	isAdmin,
	debouncedSearch,
	// isPrivate,
}) => {
	const {
		onlineChannelUsers,
		onlineChannelUsersCount,
		setOnlineChannelUsersCount,
		setOnlineChannelUsers,
	} = useServerUsersOnlineList();
	const { allChannelUsers, allChannelUsersCount } = useServerUsersAllList();

	const { handleRolesSocketMessages } = useHandleRolesSocketMessages();

	useChannel(
		{
			channelName: `private-channel:${channelId}`,
			onChannelError: err => {
				filterAblyErrorsToSentry(err);
			},
			onConnectionError: err => {
				filterAblyErrorsToSentry(err);
			},
		},
		message => {
			handleRolesSocketMessages(message);
		},
	);

	const {
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		isLoading: allUsersLoading,
	} = useGetChannelAllUsers(
		debouncedSearch,
		isAdmin,
		channelId ? +channelId : 0,
	);
	const { data: channelOnlineUsersData, isLoading: onlineUsersLoading } =
		useGetChannelOnlineUsers(
			debouncedSearch,
			channelId ? +channelId : 0,
			99,
		);

	useEffect(() => {
		if (channelOnlineUsersData?.value) {
			setOnlineChannelUsers(channelOnlineUsersData.value.users || []);
			setOnlineChannelUsersCount(channelOnlineUsersData.value.count || 0);
		}
	}, [channelOnlineUsersData?.value]);

	const userIds = useMemo(
		() => onlineChannelUsers.map(user => user.userId),
		[onlineChannelUsers],
	);

	usePollingServerUsers(userIds, debouncedSearch === '');

	if (
		!allUsersLoading &&
		!!debouncedSearch?.length &&
		!onlineChannelUsers?.length &&
		!allChannelUsers?.length
	) {
		return <EmptyListMessage text="User not found" />;
	}

	if (!onlineUsersLoading && !onlineChannelUsers?.length && !isAdmin) {
		return <EmptyListMessage text="There are currently no online users" />;
	}

	return (
		<Flex
			flexDirection="column"
			h="100%"
			p="10px"
			overflowY="auto"
			id="server-users-sidebar-scroll">
			{allUsersLoading || onlineUsersLoading ? (
				<Loader spinnerSize="sm" />
			) : (
				<>
					<Category
						name="Online"
						items={
							onlineChannelUsers.length > 99 && !isAdmin
								? onlineChannelUsers.slice(0, 99)
								: onlineChannelUsers
						}
						count={
							onlineChannelUsers.length > 99 && !isAdmin
								? '99+'
								: onlineChannelUsersCount.toString()
						}
						isOnlineUsersSection={true}
					/>
					{isAdmin ? (
						<Category
							name="All"
							items={allChannelUsers}
							count={String(allChannelUsersCount)}
							fetchNextPage={fetchNextPage}
							hasNextPage={hasNextPage}
							isFetchingNextPage={isFetchingNextPage}
						/>
					) : null}
				</>
			)}
		</Flex>
	);
};
