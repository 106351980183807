import { useQuery } from 'react-query';
import { ApiResponse, PreciselyAutocompleteItem } from '../../../types';
import API from '../../../api/api.base';
import { PreciselyPropertyDetailsResponse } from '../types';

export const useGuestPropertyDetails = (
	address: PreciselyAutocompleteItem,
	isEnabled: boolean,
) => {
	return useQuery<ApiResponse<PreciselyPropertyDetailsResponse>>(
		['property-details', address],
		() =>
			API.get('/api/app/guest/precisely/property/details', {
				Address: address.text,
				Longitude: address.coordinates[0],
				Latitude: address.coordinates[1],
			}),
		{
			enabled: isEnabled && !!address.text,
			refetchOnMount: false,
		},
	);
};
